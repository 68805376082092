.sidebar {
  background: $light;
  border-right: 1px solid $border-color;
  padding: $sidebar-padding-y $sidebar-padding-x;

  &.sidebar--closed {
    padding: $sidebar-padding-y 0;
    max-width: $sidebar-width-closed !important;
    > .sidebar--content {
      display: none;
    }
  }
  // HACK: this used with the min-height property to set sidebars height so it fills the screen.
  // see componentDidMount() on component
  // height: initial;
  // ::before {
  //   content: '';
  // }

  .pip {
    margin-right: 0.625rem;
  }

  .list-group {
    margin-bottom: $spacer;
  }

  .list-group.list-group-fill {
    margin-bottom: $spacer;
    margin-left: -$sidebar-padding-x;
    margin-right: -$sidebar-padding-x;
    border-radius: 0;

    .list-group-item {
      border-left: 0;
      border-right: 0;
      border-radius: 0;
      padding-left: $sidebar-padding-x;
      padding-top: $spacer / 2;
      padding-bottom: $spacer / 2;
      display: flex;
      align-items: center;
      outline: 0;

      a {
        color: inherit;
        display: block;
      }

      &.list-group-item-action {
        width: auto;
      }

      &.active {
        margin-right: -1px;
      }
    }
  }
}
