// Override default variables before the import
@import './theme.scss';

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

// Utils
@import 'utils';

// Import elements
@import 'elements/layout';
@import 'elements/svg';
@import 'elements/table';
@import 'elements/code';
@import 'elements/form';

// Import components
@import 'components/AppHeader';
@import 'components/Dropdown';
@import 'components/Bar';
@import 'components/PageHeader';
@import 'components/Sidebar';
@import 'components/Pip';
@import 'components/Loader';
@import 'components/Notifications';
@import 'components/Alert';
@import 'components/PaginationFooter';
@import 'components/DatePicker';
@import 'components/IconToolbar';
