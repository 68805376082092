svg {
  height: auto;
  width: $font-size-base;

  &.svg-sm {
    width: $font-size-sm;
  }

  &.svg-lg {
    width: $font-size-lg;
  }

  .dropdown-item & {
    width: 1.25rem;
    color: $gray-500;
  }
}
