.page-header {
  position: sticky;
  padding: $navbar-padding-y * 3 $navbar-padding-x;
  color: $gray-600;
  font-size: 0.75rem;

  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
    padding-top: 0;
    padding-bottom: 0;
  }

  > * {
    display: flex;
    align-items: center;
  }

  .badge {
    font-size: 0.75rem;
  }

  .page-header-column {
    border-right: 1px solid $border-color;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1rem;
    padding-right: 1rem;

    &:first-child {
      padding-left: 0;
    }

    &:last-of-type {
      border-right: 0;
    }

    @include media-breakpoint-down(sm) {
      margin: 0.75rem 0;
    }
  }

  .page-header-title h1 {
    display: flex;
    align-items: center;
    font-size: 1.125rem;
    line-height: 1.25rem;
    margin-bottom: 0;
    color: $body-color;

    .pip {
      margin-right: $spacer;
    }

    small {
      margin-left: 0.5rem;
      color: $gray-500;
      font-weight: 400;
    }

    svg {
      width: 1.5rem;
      margin-right: 0.5rem;
      color: $gray-500;
    }
  }

  .page-header-back {
    border-right-width: 0;
    width: 6rem;
    margin-right: $spacer;

    @include media-breakpoint-down(md) {
      width: auto;
      margin-right: 0;
      border-right-width: 1px;
    }
  }
}
