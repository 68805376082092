.dropdown {
  &.with-menu-icons {
    .dropdown-header {
      padding-left: 3rem;
    }

    .dropdown-item {
      padding-left: 3rem;
      line-height: 1.25rem;

      svg {
        width: 1.25rem;
        float: left;
        margin-left: -2rem;
      }
    }
  }
}

.dropdown-menu {
  border: 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 12px, rgba(0, 0, 0, 0.05) 0 1px 0;
}

.dropup.no-caret .dropdown-toggle::after {
  display: none;
}
.dropright.no-caret .dropdown-toggle::after {
  display: none;
}
.dropdown.no-caret .dropdown-toggle::after {
  display: none;
}
.dropleft.no-caret .dropdown-toggle::before {
  display: none;
}
