.pip {
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 0.125rem;
  transform: rotate(45deg);
  display: inline-block;

  &.pip-rounded {
    border-radius: 50%;
    width: 0.75rem;
    height: 0.75rem;
    transform: none;
  }
}
