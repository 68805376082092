.form-group {
  label {
    color: $gray-600;
    font-size: 0.75rem;
    margin-bottom: 0.25rem;
  }
}

.disabled {
  background-color: $gray-200;
}

.form-control.selected {
  &:hover {
    text-decoration: line-through;
    cursor: pointer;
  }
}
