.Notifications {
  position: fixed;
  top: 4rem;
  right: 0;
  max-width: 25rem;
  padding-right: 1.5rem;
  z-index: 100;

  .toast {
    margin-bottom: 1rem;
  }
}
