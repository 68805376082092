$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$enable-caret: true;

$spacer: 1rem;
$font-size-base: 0.875rem;
$font-weight-bold: 600;
$line-height-base: 1.25;
$grid-gutter-width: 3rem;

$headings-font-weight: 500;

$h1-font-size: $font-size-base * 1.75;
$h2-font-size: $font-size-base * 1.5;
$h3-font-size: $font-size-base * 1.25;
$h4-font-size: $font-size-base;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base;

$component-active-color: $gray-900;
$component-active-bg: $gray-300;

$list-group-bg: $gray-100;
$list-group-color: $gray-600;
$list-group-border-color: $gray-300;
$list-group-active-color: $gray-900;
$list-group-active-bg: $white;
$list-group-active-border-color: $gray-300;

$navbar-padding-x: 1.5rem;
$navbar-padding-y: 0.25rem;
$navbar-nav-link-padding-x: 0.75rem;
$navbar-nav-link-padding-y: 0.5rem;
$navbar-brand-font-size: 0.875rem;
$navbar-toggler-font-size: $font-size-base;
$navbar-light-color: $gray-500;
$navbar-light-hover-color: $gray-900;

$sidebar-width-open: 14rem;
$sidebar-width-closed: 4rem;
$sidebar-padding-y: 1rem;
$sidebar-padding-x: $navbar-padding-x;

$table-color: $gray-600;
$table-hover-bg: $gray-100;
$table-cell-padding: 1rem;
$table-cell-padding-sm: 0.5rem;

$badge-font-weight: inherit;

$bar-box-shadow: rgba(0, 0, 0, 0.08) 0 1px 0, rgba(0, 0, 0, 0) 0px 2px 12px;

$loader-backdrop-bg: rgba(255, 255, 255, 0.5);

$modal-inner-padding: 1rem 1.5rem;
$modal-backdrop-bg: $gray-800;
$modal-header-padding-y: 1rem;
$modal-header-padding-x: 1.5rem;
