.layout {
  display: flex;
  height: 100%;
  align-content: flex-start;
  flex-flow: row wrap;

  > * {
    // every child should be 100% width by default
    flex: 1 100%;
  }

  main.content {
    margin-left: 8rem;
    margin-right: 8rem;

    @include media-breakpoint-down(md) {
      margin-left: $spacer;
      margin-right: $spacer;
    }

    @include media-breakpoint-down(sm) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  aside.sidebar {
    max-width: $sidebar-width-open;
  }

  aside.sidebar + main.content {
    flex: 1 calc(100% - #{$sidebar-width-open});
    max-width: calc(100% - #{$sidebar-width-open});
    margin-left: 0;
    margin-right: 0;
  }
  aside.sidebar.sidebar--closed + main.content {
    flex: 1 calc(100% - #{$sidebar-width-closed});
    max-width: calc(100% - #{$sidebar-width-closed});
    margin-left: 0;
    margin-right: 0;
  }
}
