.loader {
  width: 100%;
  height: 100%;
  position: relative;
  pointer-events: auto;

  .backdrop {
    opacity: 1;
    transition: opacity 100ms;
    height: 100%;
  }

  .overlay {
    text-align: center;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;

    .text {
      margin-top: $spacer;
    }
  }

  &.loading {
    pointer-events: none;

    .backdrop {
      opacity: 0.1;
    }

    &.loader-fullscreen {
      .backdrop {
        background: rgba(255, 255, 255, 0.9);
      }
    }
  }

  &.loader-fullscreen {
    z-index: 98;
    pointer-events: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .backdrop {
      z-index: 99;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      position: absolute;
      background: rgba(255, 255, 255, 0);
      transition: background 100ms;
    }
  }
}
