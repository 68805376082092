table.table {
  line-height: 1;
  thead th {
    font-weight: 400;
    font-size: $font-size-sm;
    padding: 0.5rem $table-cell-padding;
    background: $gray-100;
  }
  &.table-sm thead th {
    padding: 0.5rem $table-cell-padding-sm;
  }
  td {
    vertical-align: middle;
    font-size: 0.75rem;
  }
  td.main-column {
    font-weight: 500;
    font-size: 0.875rem;
    color: $gray-900;

    small {
      color: $table-color;
    }
  }

  &.table-sm {
    td.with-pip {
      padding-right: $spacer / 2;
      width: $spacer;
      line-height: 0;

      .pip {
        margin-right: 0;
      }
    }
  }

  td.with-pip {
    padding-right: 0;
    width: $spacer;

    .pip {
      margin-right: 0;
    }
  }

  .pip {
    margin-right: $spacer;
  }

  .badge {
    font-size: 0.75rem;
  }

  .badge.badge-light {
    color: $table-color;
  }
}

table.table.table-links {
  td {
    cursor: pointer;
  }
}

// Currently used in contract-form (only)
table.table.equal-row-height {
  table-layout: fixed;
  td {
    min-height: 3rem;
    height: 3rem;
    padding: 0.5rem; // table sm
    & > *:only-child {
      margin-bottom: 0;
    }
    input[type='radio'].form-check-input,
    input[type='checkbox'].form-check-input {
      // TO ALING THE CHECKBOXES
      margin-top: 0;
    }
  }
}
