.AppHeader {
  background: white;
  box-shadow: $bar-box-shadow;

  .navbar-brand {
    font-weight: 500;
    width: 6rem;
    margin-right: $spacer;

    @include media-breakpoint-down(md) {
      width: auto;
    }
  }

  .nav-link {
    //font-size: $font-size-sm;

    &.active {
      font-weight: 500;
    }
  }

  .search {
    svg {
      width: 1.25rem;
      color: $gray-500;
    }
  }

  .search,
  .user-organization-dd {
    border-right: $border-width solid $border-color;
  }

  .user-organization-dd {
    ::after {
      vertical-align: 0.3rem;
      margin-left: 0.5rem;
    }
  }

  .user-organization-text {
    text-align: right;
    display: inline-block;
    font-size: $font-size-sm;

    > * {
      display: block;
      line-height: 0.75rem;
    }

    .user-text {
      color: $body-color;
    }

    .organization-text {
    }
  }

  .add-content-dd {
    .nav-link {
      padding-right: 0;
    }

    .add-content-btn {
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      line-height: 0;
      padding: 0;
      text-align: center;
      border-radius: 50%;

      svg {
        width: 1rem;
      }
    }
  }
}

.AddContent {
  padding: 0;

  .nav-item {
    padding: 0 !important;
    display: flex;
    align-items: center;
  }
}
